<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="230px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品生产批次编号：" prop="docNum">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.docNum }}</p>
                <el-input :disabled="true" v-else :class="!hasPid ? 'my-input-btn' : ''" v-model="ruleForm.docNum" placeholder="请选择产品生产批次编号">
                  <el-button v-if="!hasPid" slot="append" @click="chooseType('getbatchList')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input :disabled="true" clearable v-else v-model="ruleForm.productName" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p v-if="editType != 'look'" class="tips-red mr75">投入品批次信息为空时，从“投入品购买管理”-‘新增”录入数据。</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="肥料/饲料登记批次编号：" prop="registerBatch">
                <el-select v-model="ruleForm.registerBatch" class="full-width" @change="registerChange" clearable :placeholder="editType == 'look' ? '' : '请选择肥料/饲料登记批次编号'" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.register"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="肥料/饲料名称：" prop="inputsName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.inputsName }}</p>
                <el-input :disabled="true" clearable v-else v-model="ruleForm.inputsName" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="使用目的：" prop="purpose">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.purpose }}</p>
                <el-input clearable v-else v-model="ruleForm.purpose" placeholder="请输入使用目的" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="施用目的：" prop="purpose">
                <el-radio-group v-model="ruleForm.purpose" :disabled="editType == 'look'">
                  <el-radio
                    v-for="(item, index) in selectObj.purpose"
                    :key="index"
                    :label="item.value"> {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="使用面积（亩）禽畜数量（只）：" prop="useArea">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.useArea }}</p>
                <el-input clearable v-else @input="useAreaChange" v-model="ruleForm.useArea" placeholder="请输入使用面积（亩）禽畜数量（只）" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p v-if="editType != 'look'" class="tips-red mr135">剩余数量：{{ ruleForm.remainingQuantity || 0 }}</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="使用数量：" prop="useNum">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.useNum || '') + (ruleForm.useNumUnit || '')}}</p>
                <el-input clearable v-else @input="useNumChange" v-model="ruleForm.useNum" placeholder="请输入使用数量" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.useNumUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.snumUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p v-if="editType != 'look'" class="tips-red mr135">剩余重量：{{ ruleForm.remainingWeight || 0 }}</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="使用重量：" prop="useWeight">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.useWeight || '') + (ruleForm.useWeightUnit || '')}}</p>
                <el-input clearable v-else @input="useWeightChange" v-model="ruleForm.useWeight" placeholder="请输入使用重量" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.useWeightUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.saleUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="使用剂量：" prop="useDose">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.useDose }}</p>
                <el-input :disabled="true" clearable v-else v-model="ruleForm.useDose" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="施用人：" prop="applyer">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.applyer }}</p>
                <el-input clearable v-else v-model="ruleForm.applyer" placeholder="请输入施用人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="使用日期：" prop="useDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.useDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.useDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择使用日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="defaultObj.allProductType == '02' || defaultObj.allProductType == '03'">
            <el-col :span="24">
              <el-form-item label="使用栏舍号/鱼塘号：" prop="columnCode">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.columnCode }}</p>
                <el-input clearable v-else v-model="ruleForm.columnCode" placeholder="请输入使用栏舍号/鱼塘号" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="肥料/饲料使用过程图片：" prop="imagesUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.imagesUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.imagesUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.imagesUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="肥料/饲料使用过程视频：" prop="videoUrl" class="my-upload-files">
                <div class="all-video" v-for="(item, index) in ruleForm.videoUrl" :key="index">
                  <video :src="item" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                </div>
                <div v-if="editType == 'look' && !ruleForm.videoUrl.length" class="no-data">无视频</div>
                <div v-if="editType != 'look'" @click="addVids" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.remark }}</p>
                <el-input clearable v-else v-model="ruleForm.remark" placeholder="请输入备注" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="" label-width="190px">
                <el-checkbox :disabled="editType == 'look'" v-model="ruleForm.show">是否扫码展示</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="410005" :businessCode="ruleForm.useId" :imgSize="0.5" propName="imagesUrl" storeTableName="tableName" @getpics="getpics"></pics>
    <vids ref="vids" dictCode="410006" :businessCode="ruleForm.useId" propName="videoUrl" storeTableName="tableName" @getpics="getpics"></vids>
    <batch-list ref="getbatchList" @getbatchlist="getbatchList"  titleName="产品批次档案"></batch-list>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import batchList from '@/views/common/batchList.vue'
import pics from '@/views/common/pics.vue'
import vids from '@/views/common/vids.vue'
let _t = {}
export default {
  name: "fodderEdit",
  components: {
    pics,
    vids,
    batchList,
  },
  props:{
    hasPid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {   
      rules: {
        docNum: [{ required: true,  trigger: "change", message: '请选择产品生产批次编号'}],
        registerBatch: [{ required: true,  trigger: "change", message: '请选择肥料/饲料登记批次编号'}],
        inputsName: [{ required: true,  trigger: "change", message: '请输入肥料/饲料名称'}],
        purpose: [{ required: true,  trigger: "change", message: '请选择使用目的'}],
        useDate: [{ required: true,  trigger: "change", message: '请选择使用日期'}],
        useNum: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入使用数量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              let data = _t.ruleForm.remainingQuantity || 0
              if(val > data){
                callback(new Error("输入的数不应大于剩余数量"))
              }else{
                // let ust = _t.ruleForm.useArea, 
                //   contObj = _t.ruleForm
                // if(ust){
                //   let res = (val || 0) / ust
                //   _t.ruleForm.useDose = res
                // }
                // if(contObj.remainingQuantity){
                //   _t.ruleForm.useWeight = (contObj.remainingWeight / contObj.remainingQuantity) * val
                // }
                // this.$forceUpdate()
                callback()
              }
            }
          }
        }],
        useWeight: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入使用重量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              let data = _t.ruleForm.remainingWeight || 0
              if(val > data){
                callback(new Error("输入的数不应大于剩余重量"))
              }else{
                // let ust = _t.ruleForm.useArea, 
                //   contObj = _t.ruleForm
                
                // if(data){
                //   let ustNum = (contObj.remainingQuantity / data) * val
                //   _t.ruleForm.useNum = ustNum
                //   if(ust){
                //     _t.ruleForm.useDose = ustNum / ust
                //   }
                // }
                // this.$forceUpdate()
                callback()
              }
            }
          }
        }],
        useArea: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              // let ust = _t.ruleForm.useNum
              // if(val && ust){
              //   _t.ruleForm.useDose = ust / val
              // }
              // this.$forceUpdate()
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      initForm: {
        useId: '',
        docId: '',
        batchId: '',
        docNum: '',
        registerBatch: '',
        productName: '', 
        inputsName: '',
        purpose: '',
        useArea: '',
        useNum: '',
        useNumUnit: '盒',
        useWeight: '',
        useWeightUnit: '千克',
        useDose: '',
        applyer: '',
        useDate: '',
        columnCode: '',
        imagesUrl: [],
        videoUrl: [],
        tableName: '',
        remark: '',
        remainingQuantity: 0,
        remainingWeight: 0,
        show: true
      },
      selectObj: {
        purpose: CONSTPARAMS.useType,
        register: [],
        snumUnit: CONSTPARAMS.snumUnit,
        saleUnit: CONSTPARAMS.saleUnit,
      },
      defaultObj: {},
      selList: []
    };
  },
  created() {
    this.getSelList()
	},
  mounted() {
    // _t = this
  },
  methods: {
    useNumChange(val){
      if(!baseValidate.number(val)) return
      let _t = this
      if(!this.ruleForm.registerBatch){
        this.message('请先选择肥料/饲料登记批次编号', "warning")
        return
      }
      let ust = _t.ruleForm.useArea, 
        contObj = _t.ruleForm
      if(ust){
        let res = (val || 0) / ust
        _t.ruleForm.useDose = utils.getFixedNumber(res)
      }
      if(contObj.remainingQuantity){
        _t.ruleForm.useWeight = utils.getFixedNumber((contObj.remainingWeight / contObj.remainingQuantity) * val)
      }
      this.$forceUpdate()
    },
    useWeightChange(val){
      if(!baseValidate.number(val)) return
      let _t = this
      if(!this.ruleForm.registerBatch){
        this.message('请先选择肥料/饲料登记批次编号', "warning")
        return
      }
      let ust = _t.ruleForm.useArea, 
          contObj = _t.ruleForm,
          data = _t.ruleForm.remainingWeight
      if(data){
        let ustNum = (contObj.remainingQuantity / data) * val
        _t.ruleForm.useNum = utils.getFixedNumber(ustNum)
        if(ust){
          _t.ruleForm.useDose = utils.getFixedNumber(ustNum / ust)
        }
      }
      this.$forceUpdate()
    },
    useAreaChange(val){
      if(!baseValidate.number(val)) return
      let _t = this,
        ust = _t.ruleForm.useNum
      if(val && ust){
        _t.ruleForm.useDose = utils.getFixedNumber(ust / val)
      }
      this.$forceUpdate()
    },
    registerChange(val){
      let obj = this.getSelInfo(val) || {}
      this.ruleForm.inputsName = obj.inputsName || ''
      this.ruleForm.quarantinePeriod = obj.quarantinePeriod || ''
      this.ruleForm.remainingQuantity = obj.remainingQuantity || ''
      this.ruleForm.remainingWeight = obj.remainingWeight || ''
      this.ruleForm.useNumUnit = obj.useNumUnit || ''
      this.ruleForm.useWeightUnit = obj.useWeightUnit || ''
      this.ruleForm.batchId = obj.batchId || ''
    },
    async getSelList(){
      let _this = this
      request.apfinddata({
        bodyId: utils.getBodyId()
      }).then(res => {
        if(res.code == 200){
          let dataList = res.data.inputsBuyBatchList || [], resList = []
          dataList.forEach(cur => {
            resList.push({
              label: `${cur.registerBatchCode}(${cur.inputsName})`,
              value: cur.registerBatchCode
            })
          })
          _this.selectObj.register = resList
          _this.selList = dataList
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getSelInfo(id){
      let obj = {}
      if(id){
        let data = this.selList || []
        data.forEach(cur => {
          if(`${cur.registerBatchCode}` == id){
            obj = cur
          }
        })
      }
      return obj
    },
    getproductbatch(){
      let _this = this
      request.apfindproductbatch({
        docId: _this.ruleForm.doceId || ''
      }).then(res => {
        if(res.code == 200){
          _this.defaultObj = res.data || {}
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getbatchList(data){
      this.ruleForm.docId = data.docId || ''
      this.ruleForm.docNum = data.docNum || ''
      this.ruleForm.productName = data.productName || ''
      this.getproductbatch()
    },
    chooseType(name){
      this.$refs[name].initData()
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.imagesUrl)
    },
    addVids(){
      this.$refs.vids.initData(this.ruleForm.videoUrl)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          useId: id
        }
        this.ruleForm = obj
        _t.ruleForm = obj
      }else{
        this.ruleForm = ruleForm
        _t.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
      this.ruleForm.doceId && this.getproductbatch()
    },
    initEdit(){
      let obj = this.getSelInfo(this.ruleForm.registerBatch) || {}
      this.ruleForm.remainingQuantity = obj.remainingQuantity || ''
      this.ruleForm.remainingWeight = obj.remainingWeight || ''
    },
    initLook(){
      this.initEdit()
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'savefodderuserecord' : 'updatefodderuserecord',
            target = {
            ..._this.ruleForm,
            imagesUrl: _this.ruleForm.imagesUrl?.join(',') || '',
            videoUrl: _this.ruleForm.videoUrl?.join(',') || '',
            useDate: _this.ruleForm.useDate ? _this.ruleForm.useDate + ' 00:00:00' : '',
            show: _this.ruleForm.show ? '01' : '02'
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.useId = newId
              }else{
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .mr75{
    margin-left: 56px;
  }
</style>


